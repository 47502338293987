
	import http from "../../../../common/services/http";
	import { defineComponent, ref, reactive, onMounted, computed } from "vue";
	import router from "../router"

	export default defineComponent({
		setup(props) {
			const headers = {
				product: "Product",
				kg: "Per Kg",
				box: "Per Box",
			};

			const records = ref({
				data: [],
			});

			const processing = ref(false);
			const updated = ref(false);
			const products = ref([]);

			const customers = ref([]);
			const user_id = ref(0)
			const customer = computed(() => {
				return (
					customers.value?.find(
						(c: any) => c.id == user_id.value
					) || { contact: { currency: "euro" } }
				);
			});

			function fetchProducts() {
				processing.value = true;

				http.get(`/api/products?fetch=all`).then((res) => {
					products.value = res.data;
					processing.value = false;
				});
			}

			function fetchCustomers() {
				processing.value = true;

				http.get(`/api/users?role=customer&fetch=all`).then((res) => {
					customers.value = res.data;
					processing.value = false;
				});
			}

			function fetchHistory(c: any) {
				processing.value = true;

				http.get(`/api/calculations?user_id=${c}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function fetchRecords(page = 1) {
				processing.value = true;

				http.get(`/api/calculations?user_id=${user_id.value}page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function updateRecord(record: any) {
				record._method = 'PUT'
				http.post(`/api/calculations/${record.id}`, record)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/calculations/${id}`, { _method: "DELETE" }).then((res) => {
					fetchRecords();
				});
			}

			function viewCalculation(data: any) {
						document.getElementById("hideView")?.click();
				router.replace(`/calculations/${data.id}`);
			}

			onMounted(() => {
				fetchCustomers();
				fetchProducts();
			});

			return {
				headers,
				fetchRecords,
				fetchCustomers,
				customers,
				customer,
				user_id,
				processing,
				records,
				updateRecord,
				updated,
				deleteRecord,
				fetchHistory,
				products,
				viewCalculation
			};
		},
	});
